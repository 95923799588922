interface DividedSentence {
  /**
   * The first sub sentence that is less than or
   * equal the specified nax chars per line.
   */
  firstLine: string;

  /**
   * The remaining sub sentence
   */
  remainder: string;
}

/**
 * Get the first substring of a sentence that is less than
 * or equal to the maxCharsPerLine and the remaining part.
 *
 * The dividing must be at a whitespace character.
 *
 * @param sentence The sentence to divide.
 * @param maxCharsPerLine The max number of characters per line.
 */
export function divideSentence(
  sentence: string,
  maxCharsPerLine: number
): DividedSentence {
  /**
   * Regex for whitespace character
   */
  const whiteSpaceRegex = /\s/;

  /**
   * Trim the sentence
   */
  const trimmedSentence = sentence.trim();

  /**
   * The sentence length is less than or equal to maxCharsPerLine
   */
  if (trimmedSentence.length <= maxCharsPerLine) {
    return {
      firstLine: trimmedSentence,
      remainder: '',
    };
  }

  /**
   * If the character at the index value of 'maxCharsPerLine' is a
   * whitespace character, divide the sentence at that index.
   */
  if (whiteSpaceRegex.test(trimmedSentence.charAt(maxCharsPerLine))) {
    return {
      firstLine: trimmedSentence.substring(0, maxCharsPerLine),
      remainder: trimmedSentence.substring(maxCharsPerLine + 1),
    };
  } else {
    /**
     * If the character at the index value of 'maxCharsPerLine' is not a
     * whitespace character, look for the index of the last whitespace
     * character that is less than the maxCharsPerLine and divide
     * the sentence at the index
     */

    /** Get the words up to the index of the maxCharsPerLine */
    const sub = trimmedSentence.substring(0, maxCharsPerLine);

    /** Get the index of the last whitespace character */
    const wsIndex = sub.lastIndexOf(' ');

    /**
     * When there is not whitespace character before the index
     * with the value of 'maxCharsPerLine'.
     *
     * The normally happens for smaller values of 'maxCharsPerLine'.
     * For example, maxCharsPerLine values 1-5. If the take the
     * substring up to the index value of the wordPerLine,
     * there is a high chance that it falls within the
     * first word in the sentence.
     */
    if (wsIndex === -1) {
      throw new RangeError(
        "The provided 'maxCharsPerLine' is out of range to divide the sentence."
      );
    }

    return {
      firstLine: trimmedSentence.substring(0, wsIndex),
      remainder: trimmedSentence.substring(wsIndex + 1),
    };
  }
}

/**
 * Recursively split the sentence into multiple lines. Each line
 * will be less than or equal to the 'maxCharsPerLine'.
 *
 * @param sentence Sentence to split
 * @param maxCharsPerLine Max number of words per line
 * @returns Array of sentences. Each element represent one line
 */
export default function splitSentence(
  sentence: string,
  maxCharsPerLine: number
): Array<string> {
  let sentenceArray: Array<string> = [];

  const { firstLine, remainder } = divideSentence(sentence, maxCharsPerLine);

  sentenceArray = [...sentenceArray, firstLine];

  let remainingSentence = remainder;

  /**
   * Continue to divide the remainder of the sentence as long
   * as it is longer that the value of the maxCharsPerLine.
   */
  while (remainingSentence.length > maxCharsPerLine) {
    const { firstLine, remainder } = divideSentence(
      remainingSentence,
      maxCharsPerLine
    );

    sentenceArray = [...sentenceArray, firstLine];

    remainingSentence = remainder;
  }

  /**
   * Check for the remainingSentence being an empty string.
   */
  sentenceArray = remainingSentence
    ? [...sentenceArray, remainingSentence]
    : [...sentenceArray];

  return sentenceArray;
}
