// Generated with template/create-component.js
// Incentives
/**
 *
 * @packageDocumentation
 */
import Loader from 'components/common/Loader';
import React, { useEffect, useState } from 'react';
import ShareToNetwork from '../ShareToNetwork';
import ShareToCompany from '../ShareToCompany';

import { IncentivesProps } from './Incentives.types';
import { IPageFeedback } from '@types';
import * as isConfigured from 'lib/flowConfiguration';
import DeliverCoupon from '../DeliverCoupon';

const STEP = {
  baseAction: 'baseAction',
  deliverCouponWithRaise: 'deliverCouponWithRaise',
  raisedAction: 'raisedAction',
  deliverCouponFinal: 'deliverCouponFinal',
};

/**
 * order the flow steps skipping steps that are not configured
 *
 * @param data - the customization data for the feedback flow
 * @returns - array of ordered flow steps
 */
const getFlowSteps = (data: IPageFeedback): string[] => {
  const flowOrder = [];

  // add the baseAction step if configured. otherwise, we'll just deliver the coupon
  if (
    data.incentives?.baseAction && // there is a baseAction configured
    isConfigured[data.incentives.baseAction](
      // the baseAction is configured with valid data
      data[data.incentives.baseAction] || null // check by passing in the configured data, with a fallback of null
    )
  ) {
    flowOrder.push(STEP.baseAction);
  }

  // add the raisedAction step, if configured
  if (
    data.incentives?.raisedAction && // there is a raisedAction configured
    isConfigured[data.incentives.raisedAction](
      // the raisedAction is configured with valid data
      data[data.incentives.raisedAction] || null // check by passing in the configured data, with a fallback of null
    )
  ) {
    flowOrder.push(STEP.deliverCouponWithRaise);
    flowOrder.push(STEP.raisedAction);
  }

  flowOrder.push(STEP.deliverCouponFinal);

  return flowOrder;
};

const Incentives: React.FC<IncentivesProps> = ({
  company,
  data,
  testimonial,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [actionsCompleted, setActionsCompleted] = useState({});
  const incentives = data.incentives || null;

  useEffect(() => {
    if (
      (!testimonial.writtenText && !testimonial.video.ziggeoToken) ||
      !isConfigured.incentives(incentives)
    ) {
      // required data is missing, so skip this process
      props.continueFn();
    } else {
      setLoading(false);
    }
  }, []);

  // Get the ordered steps of the flow for this company
  const flowSteps = getFlowSteps(data);

  // manage the active step in the flow
  const [activeStep, setActiveStep] = useState(flowSteps[0]);

  const continueFlow = () => {
    // continue default flow
    const index = flowSteps.indexOf(activeStep);
    const next = index + 1;
    if (next === flowSteps.length) {
      // end the flow if already on the last step
      endFlow();
    } else {
      // otherwise, go to the next step
      setActiveStep(flowSteps[next]);
    }
  };

  // complete this flow by calling the parent component's next step
  const endFlow = () => {
    props.continueFn();
  };

  /** define the components for the possible incentive actions */
  const incentiveAction = {
    shareToCompany: (
      <ShareToCompany
        testimonial={testimonial}
        data={data.shareToCompany}
        continueFn={() => {
          setActionsCompleted({ ...actionsCompleted, shareToCompany: true });
          continueFlow();
        }}
        handleNotShare={continueFlow}
      />
    ),
    shareToNetwork: (
      <ShareToNetwork
        company={company}
        data={data.shareToNetwork}
        continueFn={() => {
          setActionsCompleted({ ...actionsCompleted, shareToNetwork: true });
          continueFlow();
        }}
        handleNotShare={continueFlow}
      />
    ),
  };

  return (
    <div data-testid='Incentives' className='w-full'>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Incentive action steps */}
          {(activeStep === STEP.baseAction ||
            activeStep === STEP.raisedAction) && (
            <div className={`w-full sm:w-100 sm:mx-auto`}>
              {incentiveAction[incentives[activeStep]]}
            </div>
          )}

          {/* incentive delivery */}
          {(activeStep === STEP.deliverCouponWithRaise ||
            activeStep === STEP.deliverCouponFinal) && (
            <DeliverCoupon
              testimonialId={testimonial.uid}
              company={company}
              // always give base incentive, but
              // only give raised incentive when raisedAction has been completed
              incentive={
                actionsCompleted[incentives.raisedAction]
                  ? incentives.raisedIncentive
                  : incentives.baseIncentive
              }
              expiration={incentives.expiration}
              raiseFn={
                // for deliverCouponWithRaise step, deliver coupon with continue to raised action when triggered
                activeStep === STEP.deliverCouponWithRaise
                  ? () => setActiveStep(STEP.raisedAction)
                  : null
              }
              continueFn={endFlow}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Incentives;

const incentivesIsConfigured = (data) => {
  if (
    !data?.excludeFromCollectionFlow &&
    data?.baseIncentive &&
    data.expiration
  ) {
    return true;
  }
  return false;
};

export { incentivesIsConfigured as isConfigured };
