import { db, serverTimestamp } from '.';

import { IResolution, ResolutionStatus } from '@types';

/**
 * Create a new resolution.
 *
 * @param data resoultion data.
 * @returns Id of the created resolution.
 */
export async function resolutionSubmit(
  data: Omit<IResolution, 'createdAt'>
): Promise<{ uid: string }> {
  try {
    const doc = await db.collection('resolution').add({
      ...data,
      createdAt: serverTimestamp(),
    });

    return { uid: doc.id };
  } catch (error) {
    return error;
  }
}

/**
 * Update a resolution with reviewer's contact information.
 *
 * @param resolutionId Id of the resolution to update.
 * @param userInfo Reviewer's contact information.
 * @returns Promise
 */
export async function resolutionUpdate(
  resolutionId: string,
  userInfo: { email?: string; phone?: string }
): Promise<any> {
  try {
    const docRef = db.collection('resolution').doc(resolutionId);
    return await docRef.set({ userInfo }, { merge: true });
  } catch (error) {
    return error;
  }
}

/**
 * Update the status of a resolution.
 *
 * @param resolutionId Id of the resolution to update.
 * @param newStatus the new status to be use for upating.
 * @returns Promise
 */
export async function resolutionUpdateStatus(
  resolutionId: string,
  newStatus: Exclude<ResolutionStatus, 'new'>
): Promise<any> {
  try {
    const docRef = db.collection('resolution').doc(resolutionId);
    return await docRef.update({ status: newStatus });
  } catch (error) {
    return error;
  }
}

/**
 * Update a resolution with reviewer's action.
 *
 * @param resolutionId Id of the resolution to update.
 * @param userAction the action the reviewer chose during request
 * to delay review.
 * @returns Promise
 */
export async function resolutionUpdateAction(
  resolutionId: string,
  userAction: string
): Promise<any> {
  try {
    const docRef = db.collection('resolution').doc(resolutionId);
    return await docRef.set({ action: userAction }, { merge: true });
  } catch (error) {
    return error;
  }
}

/**
 * Delete a resolution.
 *
 * @param resolutionId Id of the resolution to delete.
 * @returns Promise
 */
export async function resolutionDelete(resolutionId: string): Promise<void> {
  try {
    const docRef = db.collection('resolution').doc(resolutionId);
    return await docRef.delete();
  } catch (error) {
    return error;
  }
}
