import { useState } from 'react';

import Button from 'components/common/Button';
import CollectEmail from './CollectEmail';
import CollectPhone from './CollectPhone';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';

interface RequestContactProps {
  resolutionId: string;
  contactType?: string;
  next: () => void;
  endFlow: () => void;
}

export default function RequestContact(props: RequestContactProps) {
  const [contact, setContactType] = useState('');

  function handleSetContactType() {
    if (!props.contactType) {
      setContactType('phone');
    } else {
      setContactType(props.contactType);
    }
  }

  return (
    <>
      {!contact && (
        <DashboardPaper className='w-full sm:w-100 mx-auto px-5 py-10'>
          <div className='text-center mb-7'>
            <p>
              We'd like to make things right. Would you share contact info where
              a manager can reach you?
            </p>
          </div>

          <div className='flex justify-center'>
            <Button
              onClick={handleSetContactType}
              type='button'
              text='Yes'
              className='btn__pink--solid px-10'
            />

            <Button
              onClick={props.endFlow}
              type='button'
              text='No'
              className='btn__pink--outline ml-3 px-10'
            />
          </div>
        </DashboardPaper>
      )}

      {contact === 'email' && (
        <CollectEmail resolutionId={props.resolutionId} next={props.next} />
      )}

      {contact === 'phone' && (
        <CollectPhone resolutionId={props.resolutionId} next={props.next} />
      )}
    </>
  );
}
