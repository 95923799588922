/**
 *
 * @packageDocumentation
 */
import React, { useState } from 'react';

import Button from 'components/common/Button';

import { ShareWrittenProps } from './ShareToCompany.types';

const ShareWritten: React.FC<ShareWrittenProps> = (props) => {
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  function handleCopyReviewText() {
    const reviewText = document.querySelector('#user-review-text').textContent;

    // Create an empty textarea element
    const el = document.createElement('textarea');

    // Assign the value your want to copy to it and exec copy command
    el.value = reviewText;
    document.body.appendChild(el);

    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */

    // /* Copy the text inside the text field */
    document.execCommand('copy');

    setCopied(true);

    setError('');

    // Remove the created textarea element
    document.body.removeChild(el);
  }

  function shareFeedback(destination?: string) {
    if (!copied) {
      setError('Please copy your feedback to share.');
      return;
    }

    if (destination) {
      props.handleShare(destination);
    } else {
      props.handleShare();
    }
  }

  function shareToNetworkButtons() {
    const networks = Object.keys(props.shareNetworks);

    /**
     * Create an object with key and value using the networks
     * configured by user where the value will be the same
     * as the key name.
     *
     * E.g. { facebook: "facebook", twitter: "twitter" }
     */
    const shareNetworksButtonText = networks.reduce((acc, cur) => {
      let textObject = {};

      textObject[cur.toLocaleLowerCase()] = cur.toLocaleLowerCase();

      return { ...acc, ...textObject };
    }, {});

    return (
      <>
        <div>
          {networks.map(
            (network, index) =>
              props.shareNetworks[network] && (
                <Button
                  key={index}
                  text={shareNetworksButtonText[network]}
                  onClick={() => shareFeedback(network)}
                  className={`${
                    copied ? 'btn__pink--solid' : 'btn__pink--outline'
                  }`}
                />
              )
          )}
        </div>
        <Button
          text='No'
          onClick={props.handleNotShare}
          className='btn__pink--outline px-5'
        />
      </>
    );
  }

  return (
    <section data-testid='ShareWritten' className='w-full'>
      <p className='text-xl'>{props.shareRequest}</p>

      <div
        onClick={handleCopyReviewText}
        id='user-review-text'
        className='bg-pink-450 p-5 rounded-md cursor-pointer bg-opacity-5 text-gray-700 my-5 break-words'
      >
        {props.shareType === 'shareToCompany'
          ? props.writtenText
          : props.shareText}
      </div>

      <div className='text-center'>
        <Button
          onClick={handleCopyReviewText}
          text={`${copied ? 'Copied' : 'Click to Copy'}`}
          className={`px-10 ${
            copied ? 'btn__pink--outline' : 'btn__pink--solid'
          }`}
        />
      </div>

      <div className='text-center'>
        {error && <p className='text-sx text-red-400'>{error}</p>}
      </div>

      <div className='flex justify-between flex-wrap mt-4'>
        {props.shareType === 'shareToCompany' ? (
          <div className='text-center sm:flex sm:justify-between w-full'>
            <Button
              text="Sure, I'll help you out!"
              onClick={shareFeedback}
              className={`w-full mb-3 sm:mb-0 sm:w-min ${
                copied ? 'btn__pink--solid' : 'btn__pink--outline'
              }`}
            />
            <Button
              text='No'
              onClick={props.handleNotShare}
              className='btn__pink--outline px-10 w-full sm:w-min'
            />
          </div>
        ) : (
          <>
            {props.shareNetworks ? (
              shareToNetworkButtons()
            ) : (
              <>
                <div>
                  <Button
                    text='facebook'
                    onClick={() => shareFeedback('facebook')}
                    className={`mr-2 ${
                      copied ? 'btn__pink--solid' : 'btn__pink--outline'
                    }`}
                  />
                  <Button
                    text='twitter'
                    onClick={() => shareFeedback('twitter')}
                    className={`${
                      copied ? 'btn__pink--solid' : 'btn__pink--outline'
                    }`}
                  />
                </div>
                <Button
                  text='No'
                  onClick={props.handleNotShare}
                  className='btn__pink--outline px-5'
                />
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default ShareWritten;
