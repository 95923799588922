import { addDays, format } from 'date-fns';
import { customAlphabet } from 'nanoid';
import { useEffect, useState } from 'react';

export default function useCoupon(
  expiration: number = 365
): { couponCode: string; expiresIn: string } {
  const [couponCode, setCouponCode] = useState('');
  const [expiresIn, setExpiresIn] = useState(null);

  // generate a unique coupon id when the component loads
  useEffect(() => {
    const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXTY', 25);
    setCouponCode(nanoid());
  }, []);

  // set the expiration date when the coupon loads or the expiration (days until exp.) changes
  useEffect(() => {
    const expirationDate = format(
      addDays(new Date(), expiration),
      'MMMM d, yyy'
    );

    setExpiresIn(expirationDate);
  }, [expiration]);

  return { couponCode, expiresIn };
}
