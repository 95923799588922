// Generated with template/create-component.js
// ResolutionFlow
/**
 *
 * @packageDocumentation
 */
import React, { useState } from 'react';

import { ResolutionFlowProps } from './ResolutionFlow.types';
import { resolutionFlow as PLACEHOLDERS } from 'constants/collectionFlow';

import RequestDelay from './RequestDelay';
import CollectDetails from './CollectDetails';
import RequestContact from './RequestContact';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';
import CouponGenerator from 'components/common/CouponGenerator';
import useCoupon from 'lib/hooks/useCoupon';

const STEP = {
  requestDelay: 'requestDelay',
  requestContact: 'requestContact',
  requestFeedback: 'requestFeedback',
  deliverCoupon: 'deliverCoupon',
};

const ResolutionFlow: React.FC<ResolutionFlowProps> = (props) => {
  const [resolutionId, setResolutionId] = useState('');
  const [activeStep, setActiveStep] = useState(STEP.requestFeedback);
  const { couponCode, expiresIn } = useCoupon(props.incentivesData?.expiration);

  function next(deliverCoupon = false) {
    if (activeStep === STEP.requestFeedback) {
      setActiveStep(STEP.requestContact);
    } else if (activeStep === STEP.requestContact) {
      if (
        props.company.features.feedback.shareToCompany &&
        props.shareToCompany?.shareURL
      ) {
        setActiveStep(STEP.requestDelay);
      } else {
        props.agreeToDelayEndFlow();
      }
    } else if (activeStep === STEP.requestDelay) {
      if (deliverCoupon) {
        setActiveStep(STEP.deliverCoupon);
      } else {
        props.agreeToDelayEndFlow();
      }
    } else {
      props.agreeToDelayEndFlow();
    }
  }

  return (
    <div data-testid='ResolutionFlow' className='w-full'>
      {activeStep === STEP.requestFeedback && (
        <CollectDetails
          next={next}
          companyId={props.company.uid}
          testimonial={props.testimonial}
          setResolutionId={setResolutionId}
          headline={props.data?.requestDetails || PLACEHOLDERS.requestDetails}
        />
      )}

      {activeStep === STEP.requestContact && (
        <RequestContact
          next={next}
          endFlow={props.endFlow}
          resolutionId={resolutionId}
          contactType={props.data?.contactType}
        />
      )}

      {activeStep === STEP.requestDelay && (
        <RequestDelay
          next={next}
          endFlow={props.endFlow}
          agreeToDelayEndFlow={props.agreeToDelayEndFlow}
          resolutionId={resolutionId}
          requestDelay={props.data?.requestDelay}
          shareURL={props.shareToCompany?.shareURL}
          company={props.company}
          hasIncentives={
            props.company?.features?.feedback?.incentives &&
            props.incentivesData
              ? true
              : false
          }
        />
      )}

      {activeStep === STEP.deliverCoupon && (
        <DashboardPaper className='w-full flex flex-col items-center lg:w-5/6 lg:mx-auto xl:w-4/6 border'>
          <div className='text-2xl text-center mb-2 font-semibold text-gray-600'>
            Here's your coupon!
          </div>
          <CouponGenerator
            company={props.company}
            userName={props.testimonial.userInfo?.name}
            incentive={props.incentivesData.baseIncentive}
            expiration={expiresIn}
            couponCode={couponCode}
          />
        </DashboardPaper>
      )}
    </div>
  );
};

export default ResolutionFlow;

const isConfigured = (data) => {
  if (!data?.excludeFromCollectionFlow) {
    return true;
  }
  return false;
};

export { isConfigured };
