import { useState } from 'react';

import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/nextjs';

import { emailPattern } from 'lib/helpers';

import * as ERRORS from 'constants/errors';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';
import { resolutionUpdate } from 'lib/firebase/resolution';

interface CollectEmailProps {
  resolutionId: string;
  next: () => void;
}

export default function CollectEmail(props: CollectEmailProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  async function submitResponse(data: { email: string }) {
    try {
      setIsSubmitting(true);

      const userInfo = { email: data.email, phone: '' };

      await resolutionUpdate(props.resolutionId, userInfo);
      props.next();
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    } finally {
    }
  }

  return (
    <DashboardPaper className='w-full sm:w-100 mx-auto py-10'>
      <div className='text-center mb-7'>
        <h1 className='headline'>Please enter your email</h1>
      </div>

      <form className='text-center' onSubmit={handleSubmit(submitResponse)}>
        <input
          id='email'
          type='email'
          name='email'
          placeholder='john@example.com'
          className={`form__input ${errors.email && 'form__input--error'}`}
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
        />
        {errors.email && (
          <p className='form__error text-left'>Please enter a valid email</p>
        )}

        <div className='text-center mt-5'>
          <button
            type='submit'
            disabled={isSubmitting || !isValid}
            className='btn btn__pink--solid px-10'
          >
            {isSubmitting ? 'Saving...' : 'Next'}
          </button>
        </div>
      </form>
    </DashboardPaper>
  );
}
