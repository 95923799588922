// Generated with template/create-component.js
// DeliverCoupon
/**
 *
 * @packageDocumentation
 */
import toast from 'react-hot-toast';
import { customAlphabet } from 'nanoid';
import { format, addDays } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';

import { DeliverCouponProps } from './DeliverCoupon.types';

import { db } from 'lib/firebase';
import Button from 'components/common/Button';
import CouponGenerator from 'components/common/CouponGenerator';
import FlowStepPaper from 'components/public/PublicTestimonialFlow/FlowStepPaper';
import Loader from 'components/common/Loader';

import { ICouponEmail, ITestimonial } from '@types';
import { emailCouponToUser } from 'lib/firebase/incentives';
import useCoupon from 'lib/hooks/useCoupon';

const DeliverCoupon: React.FC<DeliverCouponProps> = ({ company, ...props }) => {
  const [isSending, setIsSending] = useState(false);
  const [isCouponSent, setIsCouponSent] = useState(false);
  const { couponCode, expiresIn } = useCoupon(props.expiration);

  const testRef = db.collection('testimonials').doc(props.testimonialId);

  useEffect(() => {
    if (!props.incentive) {
      // no incentive is specified, so skip this process
      props.continueFn();
    }
  }, []);

  // // generate a unique coupon id when the component loads
  // useEffect(() => {
  //   const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXTY', 25);
  //   setCouponCode(nanoid());
  // }, []);

  // // set the expiration date when the coupon loads or the expiration (days until exp.) changes
  // useEffect(() => {
  //   const expirationDate = format(
  //     addDays(new Date(), props.expiration ?? 365),
  //     'MMMM d, yyy'
  //   );

  //   setExpiresIn(expirationDate);
  // }, [props.expiration]);

  const [data, loading, error] = useDocumentDataOnce<ITestimonial>(testRef);

  const emailCoupon = async () => {
    const emailPayload: ICouponEmail = {
      userEmail: data.userInfo?.email,
      userName: data.userInfo?.name,
      senderName: company.type === 'client' ? company.management?.name : 'TrustScout',
      companyName: company.name,
      expirationDate: expiresIn,
      couponCode: couponCode,
      incentiveText: props.incentive,
    };



    setIsSending(true);
    try {
      const result = await emailCouponToUser(emailPayload);

      toast.success(result.data['message']);
      setIsSending(false);
      setIsCouponSent(true);
    } catch (error) {
      setIsSending(false);
      toast.error('Could email coupon. Try again');
    }
  };

  return (
    <>
      {loading ? (
        <div className='lg:w-5/6 mx-auto xl:w-4/6'>
          <Loader />
        </div>
      ) : (
        <FlowStepPaper
          data-testid='DeliverCoupon'
          className='w-full flex flex-col items-center lg:w-5/6 lg:mx-auto xl:w-4/6'
        >
          <div className='text-2xl text-center mb-2 font-semibold text-gray-600'>
            {props.raiseFn
              ? "Here's your coupon! Want to increase its value?"
              : "Here's your coupon!"}
          </div>
          <div className='text-center'>
            {props.raiseFn && (
              <>
                <button
                  type='button'
                  onClick={props.raiseFn}
                  className='btn btn__pink--solid px-10 mr-2 capitalize'
                >
                  Yes, give me more
                </button>
              </>
            )}
          </div>
          <CouponGenerator
            company={company}
            userName={data.userInfo?.name}
            incentive={props.incentive}
            expiration={expiresIn}
            couponCode={couponCode}
          />

          <Button
            className='btn__pink--solid'
            text={isSending ? 'Sending...' : 'Email Coupon'}
            onClick={emailCoupon}
            disabled={isCouponSent}
          />
        </FlowStepPaper>
      )}
    </>
  );
};

export default DeliverCoupon;
