import toast from 'react-hot-toast';
import { captureException } from '@sentry/nextjs';

import * as ERRORS from 'constants/errors';

import { ICompany } from '@types';
import Button from 'components/common/Button';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';

import { resolutionUpdateAction } from 'lib/firebase/resolution';
import { resolutionFlow as PLACEHOLDERS } from 'constants/collectionFlow';

interface RequestDelayProps {
  shareURL?: string;
  company: ICompany;
  requestDelay: string;
  resolutionId: string;
  hasIncentives: boolean;
  endFlow: () => void;
  agreeToDelayEndFlow: () => void
  next: (deliverCoupon?: boolean) => void;
}

export default function RequestDelay(props: RequestDelayProps) {
  async function handleTakeToReviewPage() {
    try {
      await resolutionUpdateAction(
        props.resolutionId,
        PLACEHOLDERS.action.proceedWithReview
      );

      if (
        !props.company.features?.feedback?.shareToCompany ||
        !props.shareURL
      ) {
        props.agreeToDelayEndFlow();
      } else {
        window.open(props.shareURL, '_blank', 'noopener,noreferrer');
        props.next();
      }
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    }
  }

  async function handleAgreeToDelayReview() {
    try {
      await resolutionUpdateAction(
        props.resolutionId,
        PLACEHOLDERS.action.delayReview
      );
      props.agreeToDelayEndFlow();
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    }
  }

  async function handleCollectCoupon() {
    try {
      await resolutionUpdateAction(
        props.resolutionId,
        PLACEHOLDERS.action.delayReview
      );
      props.next(true);
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    }
  }

  return (
    <DashboardPaper className='w-full sm:w-100 mx-auto px-5 py-10'>
      {props.hasIncentives ? (
        <>
          <div className='text-left mb-7'>
            <p className='mb-3'>
              {props.requestDelay ||
                PLACEHOLDERS.requestDelay(props.hasIncentives)}
            </p>
          </div>

          <div className='text-center'>
            <Button
              onClick={handleCollectCoupon}
              type='button'
              text="Ok, I'll take my coupon now"
              className='btn__pink--solid w-full sm:w-5/6 mb-4'
            />
          </div>
        </>
      ) : (
        <>
          <div className='text-left mb-7'>
            <p className='mb-3'>
              {props.requestDelay ||
                PLACEHOLDERS.requestDelay(props.hasIncentives)}
            </p>
          </div>

          <div className='text-center'>
            <Button
              onClick={handleAgreeToDelayReview}
              type='button'
              text="Ok, I'll give you a chance to make it right"
              className='btn__pink--solid w-full sm:w-5/6 mb-4'
            />
          </div>
        </>
      )}

      <div className='text-center'>
        <Button
          onClick={handleTakeToReviewPage}
          type='button'
          text='No, take me to your public reviews page'
          className='btn__pink--outline w-full sm:w-5/6'
        />
      </div>
    </DashboardPaper>
  );
}
