import { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/nextjs';

import * as ERRORS from 'constants/errors';
import Button from 'components/common/Button';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';

import { IResolution, ITestimonial } from '@types';
import { resolutionSubmit } from 'lib/firebase/resolution';

interface CollectDetailsProps {
  headline: string;
  companyId: string;
  testimonial: ITestimonial;
  next: () => void;
  setResolutionId: (id: string) => void;
}

function CollectDetails(props: CollectDetailsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ detail: string }>({
    defaultValues: {
      detail: props.testimonial.writtenText,
    },
  });

  async function submitResponse(data: { detail: string }) {
    try {
      setIsSubmitting(true);

      const newResolution: Omit<IResolution, 'createdAt'> = {
        companyId: props.companyId,
        rating: props.testimonial.rating,
        detail: data.detail,
        status: 'new',
        action: '',
        userInfo: {
          email: '',
          phone: '',
          name: props.testimonial?.userInfo?.name,
        },
      };

      const docId = await resolutionSubmit(newResolution);

      props.setResolutionId(docId.uid);
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    } finally {
      setIsSubmitting(false);
      props.next();
    }
  }

  useEffect(() => {
    setValue('detail', props.testimonial.writtenText);
  }, [props.testimonial.writtenText]);

  return (
    <DashboardPaper className='w-full sm:w-100 mx-auto px-5 py-10'>
      <div className='text-left mb-7'>
        <h1 className=''>{props.headline}</h1>
      </div>

      <form className='text-center' onSubmit={handleSubmit(submitResponse)}>
        <textarea
          id='detail'
          rows={4}
          name='detail'
          className={`form__input ${errors.detail && 'form__input--error'}`}
          {...register('detail', { required: true })}
        ></textarea>

        {errors.detail && (
          <p className='form__error text-left'>Please enter your comment</p>
        )}

        <div className='text-center mt-5'>
          <Button
            type='submit'
            disabled={isSubmitting || !isValid}
            text={isSubmitting ? 'Saving...' : 'Next'}
            className='btn__pink--solid px-10'
          />
        </div>
      </form>
    </DashboardPaper>
  );
}

export default CollectDetails;
