// Generated with template/create-component.js
// ShareToNetwork
/**
 *
 * @packageDocumentation
 */
import React, { useEffect, useState } from 'react';

import { ShareToNetworkProps } from './ShareToNetwork.types';
import { shareToNetwork as PLACEHOLDERS } from 'constants/collectionFlow';
import * as ROUTES from 'constants/routes';

import Loader from 'components/common/Loader';
import LocateReviewLoader from '../LocateReviewLoader';
import ShareWritten from '../ShareToCompany/ShareWritten';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';

const ShareToNetwork: React.FC<ShareToNetworkProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [shareText, setShareText] = useState('');
  const [verifying, setVerifying] = useState(false);

  function generateURL() {
    const slug = !props.company.slug ? props.company.uid : props.company.slug;
    // get the custom domain, if it exists
    const domain = !props.company.domain ? false : props.company.domain;

    if (domain) {
      // a custom domain always takes precedence
      // add the protocol
      return `https://${domain}`;
      /** handle the managed company case */
    } else if (props.company.management?.branding?.domain) {
      const agencyDomain = props.company.management.branding.domain;
      // the managing agency has set up a custom domain
      return `https://${agencyDomain}/${slug}`;
    } else {
      return `${process.env.NEXT_PUBLIC_APP_URL}/${slug}`;
    }
  }

  useEffect(() => {
    setLoading(true);

    if (props.data && props.data?.shareText) {
      setShareText(props.data.shareText);
    } else {
      if (
        props.company.features?.reviews?.video ||
        props.company.features?.reviews?.written
      ) {
        const url = `${generateURL()}${ROUTES.REVIEWS}`;
        setShareText(PLACEHOLDERS.shareText(props.company.name, url));
      } else {
        setShareText(PLACEHOLDERS.shareText(props.company.name));
      }
    }

    setLoading(false);
  }, []);

  const shareFeedback = (destination: string) => {
    // This should take user to the company's specified social media page
    const url = `https://${destination}.com`;

    window.open(url, '_blank', 'noopener,noreferrer');

    setVerifying(true);

    setTimeout(() => {
      // continue without setting verifying to false so that it continues showing the loader until the next screen loads
      props.continueFn();
    }, 5000);
  };

  return (
    <div data-testid='ShareToNetwork' className='w-full'>
      {loading ? (
        <Loader />
      ) : (
        <DashboardPaper
          className={`w-full sm:w-100 sm:mx-auto p-6 sm:p-10 flex justify-center items-center ${
            verifying && 'hidden'
          }`}
        >
          <ShareWritten
            shareRequest={props.data?.shareRequest || PLACEHOLDERS.shareRequest}
            handleShare={shareFeedback}
            shareText={shareText}
            handleNotShare={
              props.handleNotShare ? props.handleNotShare : props.continueFn
            }
            shareType='shareToNetwork'
            shareNetworks={props.data?.shareNetworks}
          />
        </DashboardPaper>
      )}

      {verifying && <LocateReviewLoader />}
    </div>
  );
};

export default ShareToNetwork;

const isConfigured = (data) => {
  if (!data?.excludeFromCollectionFlow) {
    return true;
  }
  return false;
};

export { isConfigured };
