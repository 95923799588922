// FlowStepPaper
/**
 * Simple standardized "paper" container used for all of the steps in the public testimonial flow
 * @packageDocumentation
 */
import React from 'react';

import { FlowStepPaperProps } from './FlowStepPaper.types';

const FlowStepPaper: React.FC<FlowStepPaperProps> = ({
  className,
  children,
}) => {
  return (
    <div
      data-testid='FlowStepPaper'
      className={`p-5 bg-white shadow-md rounded-xl font-light w-100 ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};

export default FlowStepPaper;
