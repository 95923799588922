// Generated with template/create-component.js
// StepName
/**
 *
 * @packageDocumentation
 */
import React from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/common/Button';
import FlowStepPaper from 'components/public/PublicTestimonialFlow/FlowStepPaper';

import { StepNameProps } from './ChatFlow.types';

const StepName: React.FC<StepNameProps> = (props) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
  } = useForm({ defaultValues: { name: '' }, mode: 'onChange' });

  return (
    <div data-testid='StepName' className='w-full'>
      <FlowStepPaper className='w-full'>
        <p className='headline mb-6 text-center'>
          {props.request || 'What should we call you?'}
        </p>

        <form className='text-center' onSubmit={handleSubmit(props.onSubmit)}>
          <input
            id='name'
            type='text'
            placeholder='Enter Your Name'
            className={`form__input ${errors.name ? 'form__input--error' : ''}`}
            {...register('name', {
              required: true,
              minLength: 2,
            })}
          />
          {errors.name && (
            <p className='form__error'>Please tell us your name</p>
          )}

          <div className='text-center mt-6'>
            <Button
              type='submit'
              text='Next'
              disabled={!isDirty || !isValid}
              className='btn__pink--solid px-10'
            />
          </div>
        </form>
      </FlowStepPaper>
    </div>
  );
};

export default StepName;
