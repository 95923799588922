// Generated with template/create-component.js
// Rating
/**
 *
 * @packageDocumentation
 */
import React, { useEffect, useState } from 'react';

import StarRatings from 'react-star-ratings';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/common/Button';

import { StepRatingProps } from './ChatFlow.types';
import FlowStepPaper from '../FlowStepPaper';

const StepRating: React.FC<StepRatingProps> = (props) => {
  const [ratingExplanation, setRatingExplanation] = useState('Excellent');

  const { control, handleSubmit, watch } = useForm<{ rating: number }>({
    defaultValues: { rating: 5 },
    mode: 'onChange',
  });

  /** set the rating explanation based on the user's rating
   * trigger this every time the rating changes
   */
  useEffect(() => {
    switch (watch('rating')) {
      case 5:
        setRatingExplanation('Excellent');
        break;
      case 4:
        setRatingExplanation('Good');
        break;
      case 3:
        setRatingExplanation('Okay');
        break;
      case 2:
        setRatingExplanation('Not so good');
        break;
      case 1:
        setRatingExplanation('Poor');
        break;
      default:
        setRatingExplanation('');
        break;
    }
  }, [watch('rating')]);

  return (
    <div data-testid='Rating' className='w-full'>
      <FlowStepPaper className='w-full'>
        <p className='headline mb-4 text-center'>
          {props.request ||
            `How would you rate your experience with ${props.companyName}?`}
        </p>

        <form onSubmit={handleSubmit(props.onSubmit)}>
          <div className='self-center text-center mb-3'>
            <Controller
              control={control}
              name='rating'
              render={({
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StarRatings
                  rating={value}
                  starRatedColor='#FFBE00'
                  changeRating={onChange}
                  numberOfStars={5}
                  name='rating'
                  activeColor='#FFBE00'
                  starHoverColor='#FFBE00'
                  starDimension='25px'
                />
              )}
            />
            <p className='form__label text-xs mt-1'>({ratingExplanation})</p>
          </div>

          <div className='text-center'>
            <Button
              type='submit'
              disabled={props.isSubmitting}
              text={props.isSubmitting ? 'Saving...' : 'Next'}
              className='btn__pink--solid px-10'
            />
          </div>
        </form>
      </FlowStepPaper>
    </div>
  );
};

export default StepRating;
