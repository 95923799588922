// Generated with template/create-component.js
// FeedbackText
/**
 *
 * @packageDocumentation
 */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/common/Button';
import FlowStepPaper from 'components/public/PublicTestimonialFlow/FlowStepPaper';

import { FeedbackQuestionProps } from './FeedbackFlow.types';

const FeedbackText: React.FC<FeedbackQuestionProps> = ({
  feedbackQuestion,
  onSubmit,
  isSubmitting,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { [feedbackQuestion.qRef]: '' },
    mode: 'onChange',
  });

  const submitResponse = (data) => {
    onSubmit({
      question: feedbackQuestion.question,
      response: data[feedbackQuestion.qRef],
      type: feedbackQuestion.type,
    });
  };

  useEffect(() => {
    /**
     * Clear the textarea field as user navigate through the different
     * feedback text questions
     */
    reset({ [`${feedbackQuestion.qRef}`]: '' });
  }, [`${feedbackQuestion.qRef}`]);

  return (
    <div data-testid='FeedbackText' className='w-full'>
      <FlowStepPaper className='w-full'>
        {/* Questions */}
        <div className='text-center mb-7'>
          <h1 className='headline'>{feedbackQuestion.question}</h1>
        </div>

        {/* User response */}
        <form className='text-center' onSubmit={handleSubmit(submitResponse)}>
          <textarea
            id={`${feedbackQuestion.qRef}`}
            rows={4}
            name={`${feedbackQuestion.qRef}`}
            className={`form__input ${
              errors[feedbackQuestion.qRef] && 'form__input--error'
            }`}
            {...register(`${feedbackQuestion.qRef}`, { required: true })}
          ></textarea>

          {errors[feedbackQuestion.qRef] && (
            <p className='form__error text-left'>
              {errors[feedbackQuestion.qRef].type === 'required' &&
                'Please enter your feedback to continue'}
            </p>
          )}

          <div className='text-center mt-3'>
            <Button
              type='submit'
              disabled={isSubmitting || !isValid}
              text={isSubmitting ? 'Saving...' : 'Next'}
              className='btn__pink--solid px-10'
            />
          </div>
        </form>
      </FlowStepPaper>
    </div>
  );
};

export default FeedbackText;
