// Generated with template/create-component.js
// FeedbackFlow
/**
 *
 * @packageDocumentation
 */
import React, { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import { captureException } from '@sentry/nextjs';

import * as ERRORS from 'constants/errors';
import FeedbackText from './FeedbackText';
import FeedbackStarRating from './FeedbackStarRating';
import { feedbackSubmit, feedbackUpdate } from 'lib/firebase/feedback';

import { IFeedback, IResponse } from '@types';
import { CustomFeedback, FeedbackFlowProps } from './FeedbackFlow.types';
import Loader from 'components/common/Loader';

/**
 * Add a key 'qRef' to each question that will be used to reference each question.
 * The value of the key will be 'question' plus the question index in the array.
 * This will also be used to hold user input in the form and to map a reponse
 * to the question the response belongs to.
 *
 * Questions from database:
 *  [
 *    { question: 'This is question one', type: 'text' },
 *    { question: 'This is question two', type: 'star rating' },
 *  ]
 *
 * After adding the reference:
 *  [
 *    { question: 'This is question one', type: 'text', qRef: 'question0' },
 *    { question: 'This is question two', type: 'star rating', qRef: 'question1' },
 *  ]
 */
function addReferenceToQuestions(questions: Array<CustomFeedback>) {
  return questions.map((question, idx) => ({
    ...question,
    qRef: `question${idx}`,
  }));
}

const FeedbackFlow: React.FC<FeedbackFlowProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  /**
   * If no questions for the feedbackFlow, continue to the next
   * stop in the testimonial flow
   * Otherwise, set up the questions
   */
  useEffect(() => {
    if (!isConfigured(props.data)) {
      props.continueFn();
    } else {
      /** set up the questions by adding the reference to each one */
      setQuestions(addReferenceToQuestions(props.data.questions));
      setLoading(false);
    }
  }, []);

  /**
   * Set the index for the first question.
   */
  const firstQuestion = 0;

  const [feedbackId, setFeedbackId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(firstQuestion);

  /**
   * Step through the feedback questions until last once.
   * Then proceed to the next process in the testimonial
   * flow
   */
  function nextQuestion() {
    if (activeQuestion < questions.length - 1) {
      const next = activeQuestion + 1;
      setActiveQuestion(next);
    } else {
      // Go to the next step in the testimonial flow process
      props.continueFn();
    }
  }

  function handleSetFeedbackId(id: string) {
    setFeedbackId(id);

    // Update the feedback Id in the feedback page
    props.setNewFeedbackId(id);
  }

  async function onSubmit(data: IResponse) {
    try {
      setIsSubmitting(true);

      /**
       * If it is the first step, create a new feedback document with
       * the company Id and return the feedback document id to be
       * to update the document with other reponses.
       */
      if (questions.indexOf(questions[activeQuestion]) === firstQuestion) {
        const feedback: IFeedback = {
          testimonialId: '',
          companyId: props.company.uid,
          responses: [{ ...data }],
          userInfo: { email: '', name: '' },
        };

        const feedbackUid = await feedbackSubmit(feedback);

        /**
         * Save the feedbackId to be used to update responses
         */
        handleSetFeedbackId(feedbackUid.uid);
      } else {
        await feedbackUpdate(feedbackId, data);
      }
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    } finally {
      setIsSubmitting(false);

      nextQuestion();
    }
  }

  return (
    <div data-testid='FeedbackFlow' className='w-full sm:w-100'>
      {loading ? (
        <Loader />
      ) : (
        <>
          {questions[activeQuestion] &&
          questions[activeQuestion].type === 'text' ? (
            <FeedbackText
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
              feedbackQuestion={questions[activeQuestion]}
            />
          ) : (
            <FeedbackStarRating
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
              feedbackQuestion={questions[activeQuestion]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FeedbackFlow;

const isConfigured = (data) => {
  if (!data?.excludeFromCollectionFlow && data && data.questions?.length) {
    return true;
  }
  return false;
};

export { isConfigured };
