import { useState } from 'react';

import toast from 'react-hot-toast';
import { captureException } from '@sentry/nextjs';
import { Controller, useForm } from 'react-hook-form';

import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

import * as ERRORS from 'constants/errors';
import DashboardPaper from 'components/dashboard/common/DashboardPaper';
import { resolutionUpdate } from 'lib/firebase/resolution';

interface CollectPhoneProps {
  resolutionId: string;
  next: () => void;
}

export default function CollectPhone({
  resolutionId,
  next,
}: CollectPhoneProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit } = useForm();

  async function onSubmit(data: { phone: string }) {
    try {
      setIsSubmitting(true);

      const userInfo = { email: '', phone: data.phone };

      await resolutionUpdate(resolutionId, userInfo);
      next();
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      captureException(error);
    } finally {
    }
  }

  return (
    <DashboardPaper className='w-full sm:w-100 mx-auto py-10'>
      <div className='headline text-center mb-7'>
        <h1 className=''>Please enter your phone number</h1>
      </div>

      <form className='' onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='phone'
          rules={{ required: true }}
          render={({ field: { onChange, value }, formState: { errors } }) => (
            <>
              <PhoneInput
                containerClass='w-full'
                country='us'
                value={value}
                specialLabel=''
                onChange={onChange}
                inputStyle={{ minWidth: '100%' }}
                countryCodeEditable={false}
                enableSearch={true}
              />
              {errors.phone && (
                <p className='form__error text-left'>
                  Please enter your phone number
                </p>
              )}
            </>
          )}
        />

        <div className='text-center mt-5'>
          <button
            type='submit'
            disabled={isSubmitting}
            className='btn btn__pink--solid px-10'
          >
            {isSubmitting ? 'Saving...' : 'Next'}
          </button>
        </div>
      </form>
    </DashboardPaper>
  );
}
