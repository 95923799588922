// Generated with template/create-component.js
// Button
/**
 *
 * @packageDocumentation
 */
import React from 'react';

import { ButtonProps } from './Button.types';

const Button: React.FC<ButtonProps> = ({
  type,
  onClick,
  className,
  text,
  ...rest
}) => {
  return (
    <button
      data-testid='Button'
      type={type || 'button'}
      onClick={onClick}
      className={`btn ${className}`}
      {...rest}
    >
      {text}
    </button>
  );
};

export default Button;
