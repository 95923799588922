import { ICouponEmail } from '@types';
import { functions } from '.';

/**
 * email coupon to user
 */
export const emailCouponToUser = async (payload: ICouponEmail) => {
  const sendCouponByEmail = functions.httpsCallable('sendCouponByEmail');

  return await sendCouponByEmail(payload);
};
