import firebase, { db, docToJSON, serverTimestamp } from '.';
import { IFeedback, IResponse } from '@types';

/**
 * Submit a new feedback
 */
export async function feedbackSubmit(
  feedback: IFeedback
): Promise<{ uid: string }> {
  try {
    const feedbackDoc = await db.collection('feedback').add({
      ...feedback,
      createdAt: serverTimestamp(),
    });

    return { uid: feedbackDoc.id };
  } catch (error) {
    return error.message;
  }
}

/**
 * Update a feedback with response
 */
export async function feedbackUpdate(
  feedbackId: string,
  feedback: IResponse
): Promise<any> {
  try {
    return await db
      .collection('feedback')
      .doc(feedbackId)
      .update({
        responses: firebase.firestore.FieldValue.arrayUnion(feedback),
      });
  } catch (error) {
    return error.message;
  }
}

/**
 * Update a feedback with user info and testimonial Id
 */
type UpdateData = {
  feedbackId: string;
  testimonialId: string;
  rating: number | null;
  userInfo: { name?: string; email?: string };
};

export async function feedbackUserInfoUpdate({
  feedbackId,
  testimonialId,
  rating,
  userInfo,
}: UpdateData): Promise<any> {
  try {
    const docRef = db.collection('feedback').doc(feedbackId);

    return await docRef.set(
      { userInfo, testimonialId, rating },
      { merge: true }
    );
  } catch (error) {
    return error.message;
  }
}

/**
 * Get a feedback
 */
export async function feedbackGet(uid: string): Promise<IFeedback> {
  try {
    const doc = await db.collection('feedback').doc(uid).get();

    return { uid: doc.id, ...docToJSON(doc) };
  } catch (error) {
    return error.message;
  }
}
