// Generated with template/create-component.js
// FeedbackStarRating
/**
 *
 * @packageDocumentation
 */
import React from 'react';
import StarRatings from 'react-star-ratings';
import { Controller, useForm } from 'react-hook-form';

import Button from 'components/common/Button';
import FlowStepPaper from 'components/public/PublicTestimonialFlow/FlowStepPaper';

import { FeedbackQuestionProps } from './FeedbackFlow.types';

const FeedbackStarRating: React.FC<FeedbackQuestionProps> = ({
  feedbackQuestion,
  onSubmit,
  isSubmitting,
}) => {
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  const submitResponse = (data) => {
    onSubmit({
      question: feedbackQuestion.question,
      response: data[feedbackQuestion.qRef],
      type: feedbackQuestion.type,
    });
  };

  return (
    <div data-testid='FeedbackStarRating' className='w-full'>
      <FlowStepPaper className='w-full'>
        <p className='headline mb-4 text-center'>{feedbackQuestion.question}</p>

        <form onSubmit={handleSubmit(submitResponse)}>
          <div className='self-center text-center mb-3'>
            <Controller
              control={control}
              name={`${feedbackQuestion.qRef}`}
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                formState: { errors },
              }) => (
                <>
                  <StarRatings
                    rating={value}
                    starRatedColor='#FFBE00'
                    changeRating={onChange}
                    numberOfStars={5}
                    name={`${feedbackQuestion.qRef}`}
                    activeColor='#FFBE00'
                    starHoverColor='#FFBE00'
                    starDimension='25px'
                  />
                  {errors[feedbackQuestion.qRef] && (
                    <p className='form__error text-center mt-2'>
                      {errors[feedbackQuestion.qRef].type === 'required' &&
                        'Please select a rating to continue'}
                    </p>
                  )}
                </>
              )}
            />
          </div>

          <div className='text-center'>
            <Button
              type='submit'
              disabled={isSubmitting}
              text={isSubmitting ? 'Saving...' : 'Next'}
              className='btn__pink--solid px-10'
            />
          </div>
        </form>
      </FlowStepPaper>
    </div>
  );
};

export default FeedbackStarRating;
