// Generated with template/create-component.js
// ShareToCompany
/**
 * A share component to manage user's willingness to share the provided
 * feedback on the company's social page.
 *
 * - for recorded feedback, display video in {@link ShareVideo} so user
 *   can share.
 *
 * - for written feedback, display text in {@link ShareWritten} so user can
 *   copy text for sharing.
 *
 * @packageDocumentation
 */
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { ShareToCompanyProps } from './ShareToCompany.types';

import Loader from 'components/common/Loader';
import ShareWritten from './ShareWritten';
import LocateReviewLoader from '../LocateReviewLoader';
import FlowStepPaper from 'components/public/PublicTestimonialFlow/FlowStepPaper';
import { shareToCompany as PLACEHOLDERS } from 'constants/collectionFlow';

/** load dynamically, since the Ziggeo package is front end only */
const ShareVideo = dynamic(() => import('./ShareVideo'), {
  loading: () => <Loader />,
  ssr: false,
});

const ShareToCompany: React.FC<ShareToCompanyProps> = ({
  data,
  testimonial,
  continueFn,
  handleNotShare = null,
}) => {
  const [loading, setLoading] = useState(true);
  const [locatingReview, setLocatingReview] = useState(false);

  useEffect(() => {
    if (
      (!testimonial.writtenText && !testimonial.video.ziggeoToken) ||
      !isConfigured(data)
    ) {
      // required data is missing, so skip this process
      continueFn();
    } else {
      setLoading(false);
    }
  }, []);

  const shareFeedback = () => {
    window.open(data.shareURL, '_blank', 'noopener,noreferrer');

    setLocatingReview(true);

    setTimeout(() => {
      // continue without setting verifying to false so that it continues showing the loader until the next screen loads
      continueFn();
    }, 5000);
  };

  return (
    <div data-testid='ShareToCompany' className=''>
      {loading ? (
        <Loader />
      ) : (
        <FlowStepPaper
          className={`w-full sm:w-100 p-10 flex justify-center items-center pt-10 ${
            locatingReview && 'hidden'
          }`}
        >
          {testimonial.type === 'video' && (
            <ShareVideo
              shareRequest={
                data?.shareRequestVideo || PLACEHOLDERS.shareRequestVideo
              }
              handleShare={shareFeedback}
              ziggeoToken={testimonial.video.ziggeoToken}
              handleNotShare={handleNotShare ? handleNotShare : continueFn}
              shareType='shareToCompany'
            />
          )}

          {testimonial.type === 'written' && (
            <ShareWritten
              shareRequest={
                data?.shareRequestWritten || PLACEHOLDERS.shareRequestWritten
              }
              handleShare={shareFeedback}
              writtenText={testimonial.writtenText}
              handleNotShare={handleNotShare ? handleNotShare : continueFn}
              shareType='shareToCompany'
            />
          )}
        </FlowStepPaper>
      )}

      {locatingReview && <LocateReviewLoader />}
    </div>
  );
};

export default ShareToCompany;

const isConfigured = (data) => {
  if (!data?.excludeFromCollectionFlow && data?.shareURL) {
    return true;
  }
  return false;
};

export { isConfigured };
