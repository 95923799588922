// Generated with template/create-component.js
// LocateReviewLoader
/**
 *
 * @packageDocumentation
 */
import React from 'react';


const LocateReviewLoader: React.FC = () => {
  return (
    <div
      data-testid='LocateReviewLoader'
      className='bg-white w-full sm:w-100 sm:mx-auto h-56 rounded-lg shadow-lg px-20 py-8 flex items-center'
    >
      <div className='w-full'>
        <h2 className='text-gray-600 text-xl font-semibold mb-5 capitalize text-center'>
          verifying...
        </h2>
        <div className='flex items-center w-full h-4 bg-gray-200 rounded-lg relative'>
          <div
            id='locating-your-review-loader'
            className='w-40 h-full bg-pink-450 rounded-lg absolute'
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LocateReviewLoader;
