import { isConfigured as chatFlow } from 'components/public/PublicTestimonialFlow/ChatFlow';
import { isConfigured as feedbackFlow } from 'components/public/PublicTestimonialFlow/FeedbackFlow';
import { isConfigured as incentives } from 'components/public/PublicTestimonialFlow/Incentives';
import { isConfigured as resolutionFlow } from 'components/public/PublicTestimonialFlow/ResolutionFlow';
import { isConfigured as shareToCompany } from 'components/public/PublicTestimonialFlow/ShareToCompany';
import { isConfigured as shareToNetwork } from 'components/public/PublicTestimonialFlow/ShareToNetwork';

export {
  chatFlow,
  feedbackFlow,
  incentives,
  resolutionFlow,
  shareToCompany,
  shareToNetwork,
};
