// Generated with template/create-component.js
// ChatFlow
/**
 *
 * @packageDocumentation
 */
import React, { useState } from 'react';

import toast from 'react-hot-toast';
import { captureException } from '@sentry/nextjs';

import StepName from './StepName';
import StepRating from './StepRating';

import * as ERRORS from 'constants/errors';
import { ChatFlowProps } from './ChatFlow.types';
import { saveTestimonial } from 'lib/firebase/testimonial';

const STEP = {
  NAME: 'name',
  RATING: 'rating',
};

const ChatFlow: React.FC<ChatFlowProps> = ({
  company,
  data,
  testimonial,
  setTestimonial,
  continueFn,
}) => {
  const [activeStep, setActiveStep] = useState(STEP.NAME);
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleName = ({ name }) => {
    setName(name);
    setActiveStep(STEP.RATING);
  };

  const submitUserInfo = async (data) => {
    try {
      setIsSubmitting(true);

      // save the testimonial data
      const newTestimonial = await saveTestimonial({
        ...testimonial,
        rating: data.rating,
        userInfo: { name: name, email: '' },
      });

      // set the testimonial reference for the parent component
      setTestimonial(newTestimonial);
      // continue with the function from the parent component
      continueFn(data.rating);
    } catch (error) {
      toast.error(ERRORS.DEFAULT);
      setIsSubmitting(false);
      captureException(error);
    }
  };

  return (
    <div data-testid='ChatFlow' className='w-full sm:w-100'>
      {activeStep === STEP.NAME ? (
        <StepName onSubmit={handleName} request={data?.requestName || null} />
      ) : (
        <StepRating
          companyName={company.name || 'Us'}
          request={data?.requestRating || null}
          isSubmitting={isSubmitting}
          onSubmit={submitUserInfo}
        />
      )}
    </div>
  );
};

export default ChatFlow;

const isConfigured = (data) => {
  if (!data?.excludeFromCollectionFlow) {
    return true;
  }
  return false;
};

export { isConfigured };
